var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"8","lg":"8"}},[_c('b-card',{attrs:{"no-body":""}},[_c('b-card-header',[_c('b-card-title',[_vm._v(" Обновить предложение ")])],1),_c('b-card-body',[_c('validation-observer',{ref:"form"},[_c('b-form',[_c('b-form-group',{staticClass:"form-group-with-label"},[_c('validation-provider',{attrs:{"name":"user","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('span',{class:{
                                    'text-muted' : errors.length > 0 ? false : true,
                                    'font-weight-bolder': errors.length > 0 ? true : false,
                                    'text-danger' : errors.length > 0 ? true : false
                                },staticStyle:{"font-size":"12px"}},[_vm._v("Тип предложения")]),_c('v-select',{attrs:{"options":_vm.types,"label":"name","clearable":false,"placeholder":"Выбрать","disabled":""},model:{value:(_vm.form.type),callback:function ($$v) {_vm.$set(_vm.form, "type", $$v)},expression:"form.type"}},[_c('template',{slot:"no-options"},[_vm._v(" Нет результатов поиcка ")])],2)]}}])})],1),(_vm.form.type && _vm.form.type.value === 1)?[_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"4","lg":"4"}},[_c('b-form-group',{staticClass:"form-group-with-label"},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                var errors = ref.errors;
return [_c('span',{class:{
                                                'text-muted' : errors.length > 0 ? false : true,
                                                'font-weight-bolder': errors.length > 0 ? true : false,
                                                'text-danger' : errors.length > 0 ? true : false
                                            },staticStyle:{"font-size":"12px"}},[_vm._v("Заголовок предложения")]),_c('b-form-input',{attrs:{"id":"name"},model:{value:(_vm.form.title),callback:function ($$v) {_vm.$set(_vm.form, "title", $$v)},expression:"form.title"}})]}}],null,false,2064025195)})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4","lg":"4"}},[_c('b-form-group',{staticClass:"form-group-with-label"},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                            var errors = ref.errors;
return [_c('span',{class:{
                                                'text-muted' : errors.length > 0 ? false : true,
                                                'font-weight-bolder': errors.length > 0 ? true : false,
                                                'text-danger' : errors.length > 0 ? true : false
                                            },staticStyle:{"font-size":"12px"}},[_vm._v("Заголовок предложения (en)")]),_c('b-form-input',{attrs:{"id":"name"},model:{value:(_vm.form.title_en),callback:function ($$v) {_vm.$set(_vm.form, "title_en", $$v)},expression:"form.title_en"}})]}}],null,false,2661816149)})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4","lg":"4"}},[(!_vm.offer.special_offer)?_c('b-form-group',{staticClass:"form-group-with-label"},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                            var errors = ref.errors;
return [_c('span',{class:{
                                                'text-muted' : errors.length > 0 ? false : true,
                                                'font-weight-bolder': errors.length > 0 ? true : false,
                                                'text-danger' : errors.length > 0 ? true : false
                                            },staticStyle:{"font-size":"12px"}},[_vm._v("Действует до")]),_c('flat-pickr',{staticClass:"form-control",attrs:{"config":{ enableTime: true, dateFormat: 'Y-m-d H:i'}},model:{value:(_vm.form.until),callback:function ($$v) {_vm.$set(_vm.form, "until", $$v)},expression:"form.until"}})]}}],null,false,4235483494)})],1):_vm._e()],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"6","lg":"6"}},[_c('b-form-group',{staticClass:"form-group-with-label"},[_c('div',{staticClass:"d-flex justify-content-between",staticStyle:{"margin-bottom":"4px"}},[_c('div',{staticStyle:{"flex-grow":"1"}},[_c('validation-provider',{attrs:{"rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                            var errors = ref.errors;
return [_c('span',{class:{
                                                        'text-muted' : errors.length > 0 ? false : true,
                                                        'font-weight-bolder': errors.length > 0 ? true : false,
                                                        'text-danger' : errors.length > 0 ? true : false
                                                    },staticStyle:{"font-size":"12px"}},[_vm._v("Мощность без скидки")]),_c('b-form-input',{attrs:{"type":"number","placeholder":"Мощность контракта без скидки"},model:{value:(_vm.form.old_ths),callback:function ($$v) {_vm.$set(_vm.form, "old_ths", _vm._n($$v))},expression:"form.old_ths"}})]}}],null,false,3057769344)})],1),_c('div',{staticClass:"mt-auto mb-auto"},[_c('span',{staticClass:"text-muted"},[_vm._v("TH/s")])])]),_c('vue-slider',{attrs:{"min":10,"max":500,"dotSize":16,"height":6,"contained":false,"interval":1,"duration":0.2,"tooltip":"none"},model:{value:(_vm.form.old_ths),callback:function ($$v) {_vm.$set(_vm.form, "old_ths", $$v)},expression:"form.old_ths"}})],1),_c('div',{staticClass:"d-flex justify-content-between mb-1"},[_c('span',{staticClass:"text-muted",staticStyle:{"font-size":"12px"}},[_vm._v("10 TH/s")]),_c('span',{staticClass:"text-muted",staticStyle:{"font-size":"12px"}},[_vm._v("500 TH/s")])])],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"6"}},[_c('b-form-group',{staticClass:"form-group-with-label"},[_c('validation-provider',{attrs:{"rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                                    var errors = ref.errors;
return [_c('span',{class:{
                                                'text-muted' : errors.length > 0 ? false : true,
                                                'font-weight-bolder': errors.length > 0 ? true : false,
                                                'text-danger' : errors.length > 0 ? true : false
                                            },staticStyle:{"font-size":"12px"}},[_vm._v("Скидка")]),_c('b-form-input',{attrs:{"id":"discount_percent","type":"number"},model:{value:(_vm.form.discount_percent),callback:function ($$v) {_vm.$set(_vm.form, "discount_percent", $$v)},expression:"form.discount_percent"}})]}}],null,false,123155479)})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"6","lg":"6"}},[_c('b-form-group',{staticClass:"form-group-with-label"},[_c('div',{staticClass:"d-flex justify-content-between",staticStyle:{"margin-bottom":"4px"}},[_c('div',{staticStyle:{"flex-grow":"1"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                            var errors = ref.errors;
return [_c('span',{class:{
                                                        'text-muted' : errors.length > 0 ? false : true,
                                                        'font-weight-bolder': errors.length > 0 ? true : false,
                                                        'text-danger' : errors.length > 0 ? true : false
                                                    },staticStyle:{"font-size":"12px"}},[_vm._v("Мощность")]),_c('b-form-input',{attrs:{"type":"number","placeholder":"Мощность контракта"},model:{value:(_vm.form.ths),callback:function ($$v) {_vm.$set(_vm.form, "ths", _vm._n($$v))},expression:"form.ths"}})]}}],null,false,834161336)})],1),_c('div',{staticClass:"mt-auto mb-auto"},[_c('span',{staticClass:"text-muted"},[_vm._v("TH/s")])])]),_c('vue-slider',{attrs:{"min":10,"max":500,"dotSize":16,"height":6,"contained":false,"interval":1,"duration":0.2,"tooltip":"none"},model:{value:(_vm.form.ths),callback:function ($$v) {_vm.$set(_vm.form, "ths", $$v)},expression:"form.ths"}})],1),_c('div',{staticClass:"d-flex justify-content-between mb-1"},[_c('span',{staticClass:"text-muted",staticStyle:{"font-size":"12px"}},[_vm._v("10 TH/s")]),_c('span',{staticClass:"text-muted",staticStyle:{"font-size":"12px"}},[_vm._v("500 TH/s")])])],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"6"}},[_c('b-form-group',{staticClass:"form-group-with-label"},[_c('div',{staticClass:"d-flex justify-content-between",staticStyle:{"margin-bottom":"4px"}},[_c('div',{staticStyle:{"flex-grow":"1"}},[_c('validation-provider',{attrs:{"name":"period","rules":"required|integer"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                                    var errors = ref.errors;
return [_c('span',{class:{
                                                        'text-muted' : errors.length > 0 ? false : true,
                                                        'font-weight-bolder': errors.length > 0 ? true : false,
                                                        'text-danger' : errors.length > 0 ? true : false
                                                    },staticStyle:{"font-size":"12px"}},[_vm._v("Период")]),_c('b-form-input',{attrs:{"type":"number","placeholder":"Срок действия контракта","min":12,"max":24,"formatter":_vm.periodFormatter},model:{value:(_vm.form.period),callback:function ($$v) {_vm.$set(_vm.form, "period", _vm._n($$v))},expression:"form.period"}})]}}],null,false,3146465561)})],1),_c('div',{staticClass:"mt-auto mb-auto"},[_c('span',{staticClass:"text-muted"},[_vm._v("мес.")])])]),_c('vue-slider',{attrs:{"min":12,"max":36,"dotSize":16,"height":6,"contained":false,"interval":1,"duration":0.2,"tooltip":"none"},model:{value:(_vm.form.period),callback:function ($$v) {_vm.$set(_vm.form, "period", $$v)},expression:"form.period"}})],1),_c('div',{staticClass:"d-flex justify-content-between mb-1"},[_c('span',{staticClass:"text-muted",staticStyle:{"font-size":"12px"}},[_vm._v("12 месяцев")]),_c('span',{staticClass:"text-muted",staticStyle:{"font-size":"12px"}},[_vm._v("36 месяцев")])])],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"6","lg":"6"}},[_c('b-form-group',{staticClass:"form-group-with-label"},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                                    var errors = ref.errors;
return [_c('span',{class:{
                                                'text-muted' : errors.length > 0 ? false : true,
                                                'font-weight-bolder': errors.length > 0 ? true : false,
                                                'text-danger' : errors.length > 0 ? true : false
                                            },staticStyle:{"font-size":"12px"}},[_vm._v("Текст акцента")]),_c('b-form-input',{attrs:{"id":"name"},model:{value:(_vm.form.caption),callback:function ($$v) {_vm.$set(_vm.form, "caption", $$v)},expression:"form.caption"}})]}}],null,false,2187234006)})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"6"}},[_c('b-form-group',{staticClass:"form-group-with-label"},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                            var errors = ref.errors;
return [_c('span',{class:{
                                                'text-muted' : errors.length > 0 ? false : true,
                                                'font-weight-bolder': errors.length > 0 ? true : false,
                                                'text-danger' : errors.length > 0 ? true : false
                                            },staticStyle:{"font-size":"12px"}},[_vm._v("Текст акцента (en)")]),_c('b-form-input',{attrs:{"id":"name"},model:{value:(_vm.form.caption_en),callback:function ($$v) {_vm.$set(_vm.form, "caption_en", $$v)},expression:"form.caption_en"}})]}}],null,false,2297839016)})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"6","lg":"6"}},[_c('b-form-group',{staticClass:"form-group-with-label"},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                            var errors = ref.errors;
return [_c('span',{class:{
                                                'text-muted' : errors.length > 0 ? false : true,
                                                'font-weight-bolder': errors.length > 0 ? true : false,
                                                'text-danger' : errors.length > 0 ? true : false
                                            },staticStyle:{"font-size":"12px"}},[_vm._v("Текст действия")]),_c('b-form-input',{attrs:{"id":"name"},model:{value:(_vm.form.action),callback:function ($$v) {_vm.$set(_vm.form, "action", $$v)},expression:"form.action"}})]}}],null,false,2380275406)})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"6"}},[_c('b-form-group',{staticClass:"form-group-with-label"},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                            var errors = ref.errors;
return [_c('span',{class:{
                                                'text-muted' : errors.length > 0 ? false : true,
                                                'font-weight-bolder': errors.length > 0 ? true : false,
                                                'text-danger' : errors.length > 0 ? true : false
                                            },staticStyle:{"font-size":"12px"}},[_vm._v("Текст действия (en)")]),_c('b-form-input',{attrs:{"id":"name"},model:{value:(_vm.form.action_en),callback:function ($$v) {_vm.$set(_vm.form, "action_en", $$v)},expression:"form.action_en"}})]}}],null,false,718166256)})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"4","lg":"4"}},[_c('b-form-group',{staticClass:"form-group-with-label"},[_c('validation-provider',{attrs:{"rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                            var errors = ref.errors;
return [_c('span',{class:{
                                                'text-muted' : errors.length > 0 ? false : true,
                                                'font-weight-bolder': errors.length > 0 ? true : false,
                                                'text-danger' : errors.length > 0 ? true : false
                                            },staticStyle:{"font-size":"12px"}},[_vm._v("Стоимость без скидки")]),_c('b-form-input',{attrs:{"id":"name","type":"number"},model:{value:(_vm.form.old_price_amount),callback:function ($$v) {_vm.$set(_vm.form, "old_price_amount", _vm._n($$v))},expression:"form.old_price_amount"}})]}}],null,false,1056263989)})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4","lg":"4"}},[_c('b-form-group',{staticClass:"form-group-with-label"},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                            var errors = ref.errors;
return [_c('span',{class:{
                                                'text-muted' : errors.length > 0 ? false : true,
                                                'font-weight-bolder': errors.length > 0 ? true : false,
                                                'text-danger' : errors.length > 0 ? true : false
                                            },staticStyle:{"font-size":"12px"}},[_vm._v("Стоимость")]),_c('b-form-input',{attrs:{"id":"name","type":"number"},model:{value:(_vm.form.price_amount),callback:function ($$v) {_vm.$set(_vm.form, "price_amount", _vm._n($$v))},expression:"form.price_amount"}})]}}],null,false,2021921579)})],1)],1)],1)]:_vm._e(),(_vm.form.type && _vm.form.type.value === 2)?[_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"4","lg":"4"}},[_c('b-form-group',{staticClass:"form-group-with-label"},[_c('validation-provider',{attrs:{"name":"user","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                            var errors = ref.errors;
return [_c('span',{class:{
                                                'text-muted' : errors.length > 0 ? false : true,
                                                'font-weight-bolder': errors.length > 0 ? true : false,
                                                'text-danger' : errors.length > 0 ? true : false
                                            },staticStyle:{"font-size":"12px"}},[_vm._v("Майнер")]),_c('v-select',{attrs:{"options":_vm.miners,"label":"name","clearable":false,"placeholder":"Поиск майнера"},on:{"search":_vm.fetchMiners},model:{value:(_vm.form.miner),callback:function ($$v) {_vm.$set(_vm.form, "miner", $$v)},expression:"form.miner"}},[_c('template',{slot:"no-options"},[_vm._v(" Нет результатов поиcка ")])],2)]}}],null,false,334880228)})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4","lg":"4"}},[_c('b-form-group',{staticClass:"form-group-with-label"},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                            var errors = ref.errors;
return [_c('span',{class:{
                                                'text-muted' : errors.length > 0 ? false : true,
                                                'font-weight-bolder': errors.length > 0 ? true : false,
                                                'text-danger' : errors.length > 0 ? true : false
                                            },staticStyle:{"font-size":"12px"}},[_vm._v("Текст действия")]),_c('b-form-input',{attrs:{"id":"name"},model:{value:(_vm.form.action),callback:function ($$v) {_vm.$set(_vm.form, "action", $$v)},expression:"form.action"}})]}}],null,false,2380275406)})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4","lg":"4"}},[_c('b-form-group',{staticClass:"form-group-with-label"},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                            var errors = ref.errors;
return [_c('span',{class:{
                                                'text-muted' : errors.length > 0 ? false : true,
                                                'font-weight-bolder': errors.length > 0 ? true : false,
                                                'text-danger' : errors.length > 0 ? true : false
                                            },staticStyle:{"font-size":"12px"}},[_vm._v("Текст действия (en)")]),_c('b-form-input',{attrs:{"id":"name"},model:{value:(_vm.form.action_en),callback:function ($$v) {_vm.$set(_vm.form, "action_en", $$v)},expression:"form.action_en"}})]}}],null,false,718166256)})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"6","lg":"6"}},[_c('b-form-group',{staticClass:"form-group-with-label"},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                            var errors = ref.errors;
return [_c('span',{class:{
                                                'text-muted' : errors.length > 0 ? false : true,
                                                'font-weight-bolder': errors.length > 0 ? true : false,
                                                'text-danger' : errors.length > 0 ? true : false
                                            },staticStyle:{"font-size":"12px"}},[_vm._v("Стоимость")]),_c('b-form-input',{attrs:{"id":"name","type":"number"},model:{value:(_vm.form.price_amount),callback:function ($$v) {_vm.$set(_vm.form, "price_amount", _vm._n($$v))},expression:"form.price_amount"}})]}}],null,false,2021921579)})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"6"}},[(!_vm.offer.special_offer)?_c('b-form-group',{staticClass:"form-group-with-label"},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                            var errors = ref.errors;
return [_c('span',{class:{
                                                'text-muted' : errors.length > 0 ? false : true,
                                                'font-weight-bolder': errors.length > 0 ? true : false,
                                                'text-danger' : errors.length > 0 ? true : false
                                            },staticStyle:{"font-size":"12px"}},[_vm._v("Действует до")]),_c('flat-pickr',{staticClass:"form-control",attrs:{"config":{ enableTime: true, dateFormat: 'Y-m-d H:i'}},model:{value:(_vm.form.until),callback:function ($$v) {_vm.$set(_vm.form, "until", $$v)},expression:"form.until"}})]}}],null,false,4235483494)})],1):_vm._e()],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"6","lg":"6"}},[_c('b-form-group',{staticClass:"form-group-with-label"},[_c('validation-provider',{attrs:{"rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                            var errors = ref.errors;
return [_c('span',{class:{
                                                'text-muted' : errors.length > 0 ? false : true,
                                                'font-weight-bolder': errors.length > 0 ? true : false,
                                                'text-danger' : errors.length > 0 ? true : false
                                            },staticStyle:{"font-size":"12px"}},[_vm._v("Стоимость без скидки")]),_c('b-form-input',{attrs:{"id":"name","type":"number"},model:{value:(_vm.form.old_price_amount),callback:function ($$v) {_vm.$set(_vm.form, "old_price_amount", _vm._n($$v))},expression:"form.old_price_amount"}})]}}],null,false,1056263989)})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"6"}},[_c('b-form-group',{staticClass:"form-group-with-label"},[_c('validation-provider',{attrs:{"rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                            var errors = ref.errors;
return [_c('span',{class:{
                                                'text-muted' : errors.length > 0 ? false : true,
                                                'font-weight-bolder': errors.length > 0 ? true : false,
                                                'text-danger' : errors.length > 0 ? true : false
                                            },staticStyle:{"font-size":"12px"}},[_vm._v("Скидка")]),_c('b-form-input',{attrs:{"id":"discount_percent","type":"number"},model:{value:(_vm.form.discount_percent),callback:function ($$v) {_vm.$set(_vm.form, "discount_percent", $$v)},expression:"form.discount_percent"}})]}}],null,false,123155479)})],1)],1)],1)]:_vm._e(),_c('div',{staticClass:"mt-1 d-flex justify-content-end"},[_c('b-button',{attrs:{"variant":"primary"},on:{"click":_vm.submit}},[_vm._v("Сохранить")])],1)],2)],1)],1)],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4","lg":"4"}},[_c('b-card',{attrs:{"no-body":""}},[_c('b-card-header',[_c('b-card-title',[_vm._v(" Предпросмотр ")])],1),(_vm.form.type)?[(_vm.form.type.value === 1)?_c('offer-card-preview',{attrs:{"item":_vm.preview,"isPreview":true}}):[(_vm.form.miner)?_c('offer-card-preview',{attrs:{"item":_vm.preview,"isPreview":true}}):_c('b-card-body',[_c('b-card-text',{staticClass:"text-muted text-center"},[_vm._v(" Для предпросмотра карточки предложения необходимо выбрать майнер. ")])],1)]]:_c('b-card-body',[_c('b-card-text',{staticClass:"text-muted text-center"},[_vm._v(" Для предпросмотра карточки предложения необходимо выбрать тип предложения. ")])],1)],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }